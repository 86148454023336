<template>
  <section class="bg-ngray-6 w-full flex-1 h-screen overflow-auto px-6 py-4 flex flex-col gap-4">
    <div class="relative flex flex-col bg-white rounded-lg flex-1 p-1 layout-wrapper">
      <slot />
    </div>
  </section>
</template>

<style scoped>
.layout-wrapper {
  container: layout-wrapper/inline-size;
}
</style>
